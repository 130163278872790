.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

@font-face {
  font-family: "robotoregular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "catamaranregular";
  src: url("./assets/fonts/Catamaran-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "catamaranbold";
  src: url("./assets/fonts/Catamaran-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #e1f0d1 !important;
}
